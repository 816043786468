import { IonButton, IonButtons, IonContent, IonHeader, IonIcon, IonImg, IonItem, IonLabel, IonModal, IonSegment, IonSegmentButton, IonTitle, IonToolbar } from '@ionic/react';
import { logoInstagram, logoTiktok, logoTwitter, qrCodeOutline, sendOutline, logoFacebook } from 'ionicons/icons';
import React, { useContext, useState } from 'react'
import { MainLayout } from '../../layouts';
import qr from './../../assets/images/qr_img.png';
import { DiscussionEmbed } from 'disqus-react';
import { LanguageContext } from '../../contexts';

const socialLinks = [
  {
    Instagram:
    {
      logo: logoInstagram,
      spa: {
        address: 'bettingpro_es',
        link: 'https://www.instagram.com/bettingpro_es/'
      },
      por: {
        address: 'bettingprobr',
        link: 'https://www.instagram.com/bettingprobr/'
      }
    },
  },
  {
    Facebook:
    {
      logo: logoFacebook,
      por: {
        address: '@BPBettingProBR',
        link: 'https://www.facebook.com/BPBettingProBR/'
      }
    },
  },
  {
    Twitter:
    {
      logo: logoTwitter,
      spa: {
        address: '@BettingProES',
        link: 'https://twitter.com/BettingProES'
      }
    },
  },
  {
    TikTok:
    {
      logo: logoTiktok,
      spa: {
        address: '@bettingproes',
        link: 'https://www.tiktok.com/@bettingproes'
      }
    },
  },
  {
    Telegram:
    {
      logo: sendOutline,
      spa: {
        address: 'Betting Pro LATAM',
        link: 'https://t.me/BettingProLATAM'
      },
      por: {
        address: 'BettingPro BR',
        link: 'https://t.me/BettingProBR/'
      }
    },
  }
];

const Community: React.FC = () => {
  const languageContext = useContext(LanguageContext);
  const [isShareOpen, setIsShareOpen] = useState<boolean>(false);
  const [selector, setSelector] = useState<boolean>(true);

  const getStyle = () => {
    if (!selector) return {backgroundColor: '#FFFFFF'};
    return {};
  };
  return (
    <MainLayout
      title={languageContext.lang === 'spa' ? 'Comunidad' : 'Comunidade'}
      style={getStyle()}
    >
      <>
      <IonItem>
        <IonSegment value={`${selector}`} style={{marginTop: 20, marginBottom: 20}}>
          <IonSegmentButton value={`${true}`} onClick={() => setSelector(true)}>
            <IonLabel>Redes</IonLabel>
          </IonSegmentButton>
          <IonSegmentButton value={`${false}`} onClick={() => setSelector(false)}>
            <IonLabel>Chat</IonLabel>
          </IonSegmentButton>
        </IonSegment>
        </IonItem>
        {
          selector ? (
          <>
            {
              socialLinks.map((item: any) => {
                if((item[Object.keys(item)[0]])?.[languageContext.lang]) {
                  return(
                    <IonItem 
                      key={Object.keys(item)[0]}
                      onClick={(e) => {
                        e.preventDefault();
                        window.location.href=(item[Object.keys(item)[0]])?.[languageContext.lang]?.link;
                      }}
                    >
                    <IonIcon slot="start" icon={(item[Object.keys(item)[0]])?.logo} />
                    <IonLabel>
                      {(item[Object.keys(item)[0]])?.[languageContext.lang]?.address}
                      <p slot="helper">{Object.keys(item)[0]}</p>
                    </IonLabel>
                  </IonItem>    
                  );
                }
              })
            }
            <IonItem 
              onClick={(e) => {
                  e.preventDefault();
                  setIsShareOpen(true);
                }}
              >
                <IonIcon slot="start" icon={qrCodeOutline} />
              <IonLabel>
                {languageContext.lang === 'spa' ? 'Compartir' : 'Compartilhar'}
              </IonLabel>
            </IonItem>
            <IonModal isOpen={isShareOpen} onDidDismiss={()=>{ setIsShareOpen(false) }}>
              <IonHeader>
                <IonToolbar>
                  <IonTitle>{languageContext.lang === 'spa' ? 'Compartir' : 'Compartilhar'}</IonTitle>
                  <IonButtons slot="end">
                    <IonButton onClick={() => setIsShareOpen(false)}>{languageContext.lang === 'spa' ? 'Cerrar' : 'Fechar'}</IonButton>
                  </IonButtons>
                </IonToolbar>
              </IonHeader>
              <IonContent className="ion-padding">
                <div style={{display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'column'}}>
                  <p>
                    {
                      languageContext.lang === 'spa'
                      ? 'Lee este QR con otro dispositivo para acceder a este sitio.' 
                      : 'Leia este QR com outro dispositivo para acessar este site.'
                    }
                  </p>
                  <IonImg src={qr} style={{maxWidth: 400, maxHeight: 400}}/>
                </div>
              </IonContent>
            </IonModal>
          </>  
          ) : (
            <div style={{padding: 20}}>
              <DiscussionEmbed
                shortname='mundialde-futbol'
                config={
                    {
                        url: 'https://mundialde.futbol/chat',
                        identifier: 'mundialde.futbol',
                        title: 'mundialde.futbol',
                        language: languageContext.lang === 'spa' ? 'es_AR' : 'pt_BR'
                    }
                }
              />               
            </div>
          )
        }
      </>
    </MainLayout>
  )
}

export default Community