import { Redirect, Route } from 'react-router-dom';
import {
  IonApp,
  IonIcon,
  IonLabel,
  IonRouterOutlet,
  IonTabBar,
  IonTabButton,
  IonTabs,
  setupIonicReact
} from '@ionic/react';
import { IonReactRouter } from '@ionic/react-router';
import { footballOutline, gridOutline, listOutline, peopleOutline } from 'ionicons/icons';
import { LanguageContext } from './contexts';
import { useContext } from 'react';

import { Home, Fixtures, Community, Groups } from './pages';
/* Core CSS required for Ionic components to work properly */
import '@ionic/react/css/core.css';

/* Basic CSS for apps built with Ionic */
import '@ionic/react/css/normalize.css';
import '@ionic/react/css/structure.css';
import '@ionic/react/css/typography.css';

/* Optional CSS utils that can be commented out */
import '@ionic/react/css/padding.css';
import '@ionic/react/css/float-elements.css';
import '@ionic/react/css/text-alignment.css';
import '@ionic/react/css/text-transformation.css';
import '@ionic/react/css/flex-utils.css';
import '@ionic/react/css/display.css';

/* Theme variables */
import './theme/variables.css';

setupIonicReact();

const App: React.FC = () => {
  const languageContext = useContext(LanguageContext);
  return (
  <IonApp>
    <IonReactRouter>
      <IonTabs>
        <IonRouterOutlet>
          <Route exact path="/home">
            <Home />
          </Route>
          <Route exact path="/fixtures/:id?" component={Fixtures}>
            {/* <Fixtures /> */}
          </Route>
          <Route exact path="/groups">
            <Groups />
          </Route>
          <Route exact path="/community">
            <Community />
          </Route>
          <Route exact path="/">
            <Redirect to="/home" />
          </Route>
          <Route render={() => <Redirect to={'/home'} />} />
        </IonRouterOutlet>
        <IonTabBar slot="bottom">
          <IonTabButton tab="tab1" href="/home">
            <IonIcon icon={footballOutline} />
            <IonLabel>Inicio</IonLabel>
          </IonTabButton>
          <IonTabButton tab="tab2" href="/fixtures">
            <IonIcon icon={listOutline} />
            <IonLabel>{languageContext.lang === 'spa' ? 'Partidos' : 'Partidas'}</IonLabel>
          </IonTabButton>
          {/* <IonTabButton tab="tab3" href="/groups">
            <IonIcon icon={gridOutline} />
            <IonLabel>Grupos</IonLabel>
          </IonTabButton> */}
          <IonTabButton tab="tab4" href="/community">
            <IonIcon icon={peopleOutline} />
            <IonLabel>{languageContext.lang === 'spa' ? 'Comunidad' : 'Comunidade'}</IonLabel>
          </IonTabButton>
        </IonTabBar>
      </IonTabs>
    </IonReactRouter>
  </IonApp>
)};

export default App;
