import React, { useContext } from 'react'
import { IonCard, IonCardContent, IonCardHeader, IonCardSubtitle, IonIcon } from '@ionic/react';
import { TeamList, CountDown } from '../../components';
import './fixtureCard.css';
import { useEffect, useState } from 'react';
import { timeOutline } from 'ionicons/icons';
import { LanguageContext } from '../../contexts';
interface IFixtureCard {
  data: any;
}

const FixtureCard: React.FC<IFixtureCard> = ({data}) => {
  const languageContext = useContext(LanguageContext);
  const [backgroundColor, setBackgroundColor] = useState<string>('#FFFFFF');
  const [foregroundColor, setForegroundColor] = useState<string>('#000000');

  useEffect(() => {
    const el: any = document.querySelector('ion-card');
    try {
      setBackgroundColor((getComputedStyle(el).getPropertyValue("--background")).trim().substring(1));
      setForegroundColor((getComputedStyle(el).getPropertyValue("--color")).trim().substring(1));
    } catch (error) {
      
    }
    return () => {
    }
  })

  return (
    <IonCard>
      <div className='cardHeaderBg'>
        <div className='cardHeaderContent'>              
          <div className='title'>
            {data?.teams?.home?.name} vs. {data?.teams?.away?.name}
          </div>
          {
            data?.fixture?.status?.short === 'NS' && (
              <div className='countDown'>
                {
                  data?.fixture?.timestamp && (
                    <CountDown
                      timestamp={data?.fixture?.timestamp}
                    />
                  )
                }
              </div>
            )
          }
          <div className="dateTime">
            {new Date(data?.fixture?.timestamp * 1000).toLocaleDateString("en-GB")}
            &nbsp;<IonIcon icon={timeOutline} />&nbsp;
            {new Date(data?.fixture?.timestamp * 1000).toLocaleTimeString("en-GB", { hour: '2-digit', minute: '2-digit' })} Hs.
          </div>
          <div className='match'>
            <TeamList 
              name={data?.teams?.home?.name}
              picture={data?.homeCountryFlag}
              points={data?.goals?.home}
            />
            :
            <TeamList 
              name={data?.teams?.away?.name}
              picture={data?.awayCountryFlag}
              points={data?.goals?.away}
            />
          </div>
        </div>
      </div>
    <IonCardHeader>
      <IonCardSubtitle className='cardTitle'>
        - {data?.fixture?.venue?.name} -
      </IonCardSubtitle>
      <IonCardSubtitle className='cardTitle'>
        {
        !data?.fixture?.referee && (
          <IonCardSubtitle>{data?.fixture?.referee}</IonCardSubtitle>
          )
        }
      </IonCardSubtitle>
    </IonCardHeader>

    <IonCardContent>
      <div style={{overflow: 'hidden'}}>
        <iframe
        scrolling='no'
          title='predictions'
          src={`https://sportsapi-80517.web.app/?fixture=${data?.fixture?.id}&bgColor=${backgroundColor}&fgColor=${foregroundColor}&lang=${languageContext.lang === 'spa' ? 'es' : 'pt'}`} 
          style={{border: 10, width: "100%", height: '200px', overflow: "hidden"}}>
        </iframe>
      </div>
    </IonCardContent>
  </IonCard>
  )
}

export default FixtureCard