import fetcher from './fetcher'
import useSWR from 'swr';

let homeCountry: any;
let awayCountry: any;

export function useGetFixtures (fixtureId: string, selectedCountry: string | undefined, lang: string) {
  const { data, error, mutate } = useSWR(`https://widget.mundialde.futbol/${fixtureId}.json`, fetcher);
  const { data: countries, error: errorCountries } = useSWR(`https://restcountries.com/v3.1/all`, fetcher);

  // Add missing countries
  countries && countries.push(
    {name: {common: 'England'}, translations: {spa: {common: 'Inglaterra'}, por: {common: 'Inglaterra'}}, flags: {png: 'https://upload.wikimedia.org/wikipedia/en/thumb/b/be/Flag_of_England.svg/250px-Flag_of_England.svg.png'}},
    {name: {common: 'USA'}, translations: {spa: {common: 'Estados Unidos'}, por: {common: 'Estados Unidos'}}, flags: {png: 'https://upload.wikimedia.org/wikipedia/en/thumb/a/a4/Flag_of_the_United_States.svg/250px-Flag_of_the_United_States.svg.png'}},
    {name: {common: 'Wales'}, translations: {spa: {common: 'Gales'}, por: {common: 'País de Gales'}}, flags: {png: 'https://upload.wikimedia.org/wikipedia/commons/thumb/d/dc/Flag_of_Wales.svg/250px-Flag_of_Wales.svg.png'}},
  );

  if(data?.data?.response && countries) {
    data?.data?.response.forEach((fixture: any) => {
      homeCountry = countries.find((country: any) => fixture?.teams?.home?.name?.toLowerCase() === country?.name?.common.toLowerCase());
      awayCountry = countries.find((country: any) => fixture?.teams?.away?.name?.toLowerCase() === country?.name?.common.toLowerCase());
      
      if (homeCountry) {
        fixture.teams.home.name = homeCountry?.translations?.[lang]?.common || fixture.teams.home.name;
        fixture.homeCountryFlag = homeCountry?.flags?.png || fixture?.teams?.home?.logo;
      }

      if (awayCountry) {
        fixture.teams.away.name = awayCountry?.translations?.[lang]?.common || fixture.teams.away.name;  
        fixture.awayCountryFlag = awayCountry?.flags?.png || fixture?.teams?.away?.logo;
      }
    });
  }

  // filter by country
  let filteredData;
  if (selectedCountry) {
    if(data?.data?.response) {
      filteredData = data?.data?.response.filter((fixture: any) => {
        return fixture?.teams?.home?.name?.toLowerCase() === selectedCountry.toLowerCase()
        || fixture?.teams?.away?.name?.toLowerCase() === selectedCountry.toLowerCase()
      });
      data.data.response = filteredData;
    }
  }

  return {
    data: data,
    isLoading: !error && !data,
    error: error,
    mutate
  };
}