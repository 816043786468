import React, { useEffect, useState } from 'react';
import { LanguageContext } from './../../contexts';

interface ILanguageContextComponent {
  children: React.ReactNode;
}

const LanguageContextComponent: React.FC<ILanguageContextComponent> = ({children}) => {
  const [lang, setLang] = useState<string>('');

  useEffect(() => {
    const localLang = localStorage.getItem('lang');
    setLang(localLang || 'spa');
  }, [])

  useEffect(() => {
    if (lang) {
      localStorage.setItem('lang', lang);
    }
  }, [lang])
  
  return (
    <LanguageContext.Provider value={{lang, setLang}}>
      {children}
    </LanguageContext.Provider>
  );
}

export default LanguageContextComponent;
