import React, { useContext } from 'react';
import { MainLayout } from '../../layouts';
import { GroupCard, Preloader } from '../../components';
import { IonCol, IonGrid, IonRow } from '@ionic/react';
import './groups.css'
import { LanguageContext } from '../../contexts';

const Groups: React.FC = () => {
  const languageContext = useContext(LanguageContext);

  const isLoading = false;
  return (
    <MainLayout
      title='Grupos'
    >
      {
        !isLoading ? (
          <IonGrid>
            <IonRow key={1}>
              <IonCol key={10}>
                <GroupCard group="A" countries={[
                  {name: "Catar", flag: "https://flagcdn.com/qa.svg"},
                  {name: "Ecuador", flag: "https://flagcdn.com/ec.svg"},
                  {name: "Senegal", flag: "https://flagcdn.com/sn.svg"},
                  {name: languageContext.lang === 'spa' ? "Países Bajos" : "Holanda" , flag: "https://flagcdn.com/nl.svg"},
                ]}/>
              </IonCol>
              <IonCol key={11}>
                <GroupCard group="B" countries={[
                  {name: "Inglaterra", flag: "https://upload.wikimedia.org/wikipedia/en/thumb/b/be/Flag_of_England.svg/250px-Flag_of_England.svg.png"},
                  {name: languageContext.lang === 'spa' ? "Iran" : "Irã", flag: "https://flagcdn.com/ir.svg"},
                  {name: "Estados Unidos", flag: "https://flagcdn.com/us.svg"},
                  {name: languageContext.lang === 'spa' ? "Gales" : "País de Gales", flag: "https://upload.wikimedia.org/wikipedia/commons/thumb/d/dc/Flag_of_Wales.svg/270px-Flag_of_Wales.svg.png"},
                ]}/>
              </IonCol>
            </IonRow>
            <IonRow key={2}>
              <IonCol key={21}>
                <GroupCard group="C" countries={[
                  {name: "Argentina", flag: "https://flagcdn.com/ar.svg"},
                  {name: languageContext.lang === 'spa' ? "Arabia Saudí" : "Arabia Saudita", flag: "https://flagcdn.com/sa.svg"},
                  {name: "México", flag: "https://flagcdn.com/mx.svg"},
                  {name: languageContext.lang === 'spa' ? "Polonia" : "Polônia", flag: "https://flagcdn.com/pl.svg"},
                ]}/>
              </IonCol>
              <IonCol key={22}>
                <GroupCard group="D" countries={[
                  {name: languageContext.lang === 'spa' ? "Francia" : "França", flag: "https://flagcdn.com/fr.svg"},
                  {name: languageContext.lang === 'spa' ? "Australia" : "Austrália", flag: "https://flagcdn.com/au.svg"},
                  {name: "Dinamarca", flag: "https://flagcdn.com/dk.svg"},
                  {name: languageContext.lang === 'spa' ? "Túnez" : "Tunísia", flag: "https://flagcdn.com/tn.svg"},
                ]}/>
              </IonCol>
            </IonRow>
            <IonRow key={3}>
              <IonCol key={31}>
                <GroupCard group="E" countries={[
                  {name: languageContext.lang === 'spa' ? "España" : "Espanha", flag: "https://flagcdn.com/es.svg"},
                  {name: "Costa Rica", flag: "https://flagcdn.com/cr.svg"},
                  {name: languageContext.lang === 'spa' ? "Alemania" : "Alemanha", flag: "https://flagcdn.com/de.svg"},
                  {name: languageContext.lang === 'spa' ? "Japón" : "Japão", flag: "https://flagcdn.com/jp.svg"},
                ]}/>
              </IonCol>
              <IonCol key={32}>
                <GroupCard group="F" countries={[
                  {name: "Bélgica", flag: "https://flagcdn.com/be.svg"},
                  {name: "Canadá", flag: "https://flagcdn.com/ca.svg"},
                  {name: languageContext.lang === 'spa' ? "Marruecos" : "Marrocos", flag: "https://flagcdn.com/ma.svg"},
                  {name: languageContext.lang === 'spa' ? "Croacia" : "Croácia", flag: "https://flagcdn.com/hr.svg"},
                ]}/>
              </IonCol>
            </IonRow>
            <IonRow key={4}>
              <IonCol key={41}>
                <GroupCard group="G" countries={[
                  {name: "Brasil", flag: "https://flagcdn.com/br.svg"},
                  {name: languageContext.lang === 'spa' ? "Serbia" : "Sérvia", flag: "https://flagcdn.com/rs.svg"},
                  {name: languageContext.lang === 'spa' ? "Suiza" : "Suíça", flag: "https://flagcdn.com/ch.svg"},
                  {name: languageContext.lang === 'spa' ? "Camerún" : "Camarões", flag: "https://flagcdn.com/cm.svg"},
                ]}/>
              </IonCol>
              <IonCol key={42}>
                <GroupCard group="H" countries={[
                  {name: "Portugal", flag: "https://flagcdn.com/pt.svg"},
                  {name: languageContext.lang === 'spa' ? "Ghana" : "Gana", flag: "https://flagcdn.com/gh.svg"},
                  {name: languageContext.lang === 'spa' ? "Uruguay" : "Uruguai", flag: "https://flagcdn.com/uy.svg"},
                  {name: languageContext.lang === 'spa' ? "Corea del Sur" : "Coreia do Sul", flag: "https://flagcdn.com/kr.svg"},
                ]}/>
              </IonCol>
            </IonRow>
          </IonGrid>
        ) : (
          <Preloader />
        )
      }
    </MainLayout>
  )
}

export default Groups