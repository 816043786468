import fetcher from './fetcher'
import useSWR from 'swr';

let homeCountryFlag :any;
let awayCountryFlag :any;

export function useGetCurrentOrNext (fixtureId: string, lang: string) {
  const { data, error, mutate } = useSWR(`https://widget.mundialde.futbol/${fixtureId}.json?1`, fetcher);
  const { data: countries, error: errorCountries } = useSWR(`https://restcountries.com/v3.1/all`, fetcher);

  // Add missing countries
  countries && countries.push(
    {name: {common: 'England'}, translations: {spa: {common: 'Inglaterra'}, por: {common: 'Inglaterra'}}, flags: {png: 'https://upload.wikimedia.org/wikipedia/en/thumb/b/be/Flag_of_England.svg/250px-Flag_of_England.svg.png'}},
    {name: {common: 'USA'}, translations: {spa: {common: 'Estados Unidos'}, por: {common: 'Estados Unidos'}}, flags: {png: 'https://upload.wikimedia.org/wikipedia/en/thumb/a/a4/Flag_of_the_United_States.svg/250px-Flag_of_the_United_States.svg.png'}},
    {name: {common: 'Wales'}, translations: {spa: {common: 'Gales'}, por: {common: 'País de Gales'}}, flags: {png: 'https://upload.wikimedia.org/wikipedia/commons/thumb/d/dc/Flag_of_Wales.svg/250px-Flag_of_Wales.svg.png'}},
  );

  let matchFound: any;
  matchFound = data?.data?.response?.find(
    (matchInstance: any) => (
      matchInstance?.fixture?.status?.short === '1H' ||
      matchInstance?.fixture?.status?.short === '2H' ||
      matchInstance?.fixture?.status?.short === 'HT'
    ));

  if (!matchFound || matchFound?.length === 0) {
    matchFound = data?.data?.response?.find(
      (matchInstance:any) => matchInstance?.fixture?.timestamp > (Math.floor(Date.now() / 1000)));  
    }

  // Get flags
  if(matchFound) {
  const homeCountry = countries?.find((country: any) => matchFound?.teams?.home?.name?.toLowerCase() === country?.name?.common.toLowerCase());
  const awayCountry = countries?.find((country: any) => matchFound?.teams?.away?.name?.toLowerCase() === country?.name?.common.toLowerCase());

  if(homeCountry) {homeCountryFlag = homeCountry?.flags?.png || matchFound?.teams?.home?.logo};
  if(awayCountry) {awayCountryFlag = awayCountry?.flags?.png || matchFound?.teams?.away?.logo};

  // Translate country name
    matchFound.homeCountryFlag = homeCountryFlag || '';
    matchFound.awayCountryFlag = awayCountryFlag || '';
    matchFound.teams.home.name = homeCountry?.translations?.[lang]?.common || matchFound.teams.home.name;
    matchFound.teams.away.name = awayCountry?.translations?.[lang]?.common || matchFound.teams.away.name;  
  }

  return {
    data: matchFound,
    isLoading: !error && !data,
    error: error,
    mutate,
  };
}