import React, { useContext, useEffect, useState } from 'react'
import { MainLayout } from '../../layouts';
import { IonButton, IonButtons, IonChip, IonContent, IonHeader, IonIcon, IonItem, IonLabel, IonList, IonModal, IonTitle, IonToolbar, useIonViewWillEnter } from '@ionic/react';
import { useGetFixtures } from '../../hooks';
import { TeamList, FixtureCard, Preloader, DateCard } from '../../components';
import './fixtures.css';
import { close } from 'ionicons/icons';
import { RouteComponentProps } from 'react-router';
import { LanguageContext } from '../../contexts';

interface UserDetailPageProps
  extends RouteComponentProps<{
    id: string;
  }> {}

const Fixtures: React.FC<UserDetailPageProps> = ({match}) => {
  const languageContext = useContext(LanguageContext);

  const [fixtureSelected, setFixtureSelected] = useState<number | null>(null);
  const [countryState, setCountryState] = useState<string | undefined>(undefined);
  
  const selectFixture = (fixtureId: number) => {
    const fixtureSelected = data?.data?.response?.find((data: any) => data?.fixture?.id === fixtureId);
    setFixtureSelected(fixtureSelected);    
  };
  
  let { data, error, isLoading, mutate } = useGetFixtures('34_2026', countryState, languageContext.lang);
  useEffect(() => {
    setCountryState(match?.params?.id);
  })
  
  useIonViewWillEnter (() => {
    mutate(new Date());
    setCountryState(match?.params?.id);    
  });

  return (
    <MainLayout
      title={languageContext.lang === 'spa' ? 'Partidos' : 'Partidas'}
    >
      {
        !isLoading && data ? (
          <>
            {
              countryState && (
                <IonChip className='countryChip'>
                  <IonLabel>{countryState}</IonLabel>
                  <a href="/fixtures" style={{paddingTop: 3, paddingLeft: 10}}>
                    <IonIcon icon={close}></IonIcon>
                  </a>
                </IonChip>                
              )
            }
            <IonList inset={true}>
              {
                data?.data?.response?.map((data: any) =>
                <IonItem 
                  style={{cursor: 'pointer', paddingBottom: 20}}
                  key={data?.fixture?.id}
                  onClick={() => selectFixture(data?.fixture?.id)}
                >
                  <TeamList
                    name={data?.teams?.home?.name}
                    picture={data?.homeCountryFlag}
                    points={data?.goals?.home}
                  />
                  <div style={{textAlign: 'center'}}>
                    <div style={{fontSize: 32}}>
                      <DateCard timestamp={data?.fixture?.timestamp} />
                    </div>
                  </div>
                  <TeamList
                    name={data?.teams?.away?.name}
                    picture={data?.awayCountryFlag}
                    points={data?.goals?.away}
                    />
                </IonItem>
              )}
            </IonList>
            <IonModal isOpen={!!fixtureSelected} onDidDismiss={()=>{ setFixtureSelected(null) }}>
              <IonHeader>
                <IonToolbar>
                  <IonTitle>Partido</IonTitle>
                  <IonButtons slot="end">
                    <IonButton onClick={() => setFixtureSelected(null)}>{languageContext.lang === 'spa' ? 'Cerrar' : 'Fechar'}</IonButton>
                  </IonButtons>
                </IonToolbar>
              </IonHeader>
              <IonContent className="ion-padding">
                <FixtureCard
                  data={fixtureSelected}
                />
              </IonContent>
            </IonModal>
          </>
        ) : (
          <Preloader />
        )
      }    
    </MainLayout>
  );
}

export default Fixtures