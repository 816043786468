import { useContext } from 'react';
import { MainLayout } from '../../layouts';
import { FixtureCard, Preloader } from './../../components';
import { useGetCurrentOrNext } from '../../hooks';
import { useIonViewDidEnter } from '@ionic/react';
import { LanguageContext } from '../../contexts';
import './home.css';

const Playing: React.FC = () => {
  const languageContext = useContext(LanguageContext);

  const { data, error, isLoading, mutate } = useGetCurrentOrNext('34_2026', languageContext.lang);

  useIonViewDidEnter(() => {
    mutate(new Date());
  });

  return (
    <MainLayout
      title='Inicio'
    >
      {
        !isLoading ? (
          <FixtureCard data={data}/>
        ) : (
          <Preloader />
        )
      }
    </MainLayout>
  );
};

export default Playing;
