import { useEffect, useState } from "react";

interface ICountDown {
  timestamp: number;
}

const CountDown: React.FC<ICountDown> = ({timestamp}) => {
  const [countDown, setCountdown] = useState<any>();
  const [seconds, setSeconds] = useState<any>();

  function calcCountDown () {
    let date = new Date();
    let difference_ms = timestamp - Math.floor(date.getTime() / 1000);
    let seconds = Math.floor(difference_ms % 60);
    difference_ms = difference_ms/60; 
    let minutes = Math.floor(difference_ms % 60);
    difference_ms = difference_ms/60; 
    let hours = Math.floor(difference_ms % 24);  
    let days: number = Math.floor(difference_ms/24);  
    setCountdown({days, hours, minutes, seconds});
    setSeconds(seconds);
  }
  
  useEffect(() => {
    const interval = window.setInterval(calcCountDown, 1000);
  
    return () => {
      window.clearInterval(interval);  
    }
  }, [])
  
  
  return (
    <div>
      {countDown?.days >0 ? `${countDown?.days}d:` : ''}
      {countDown?.hours <10 ? '0' + countDown?.hours : countDown?.hours}:
      {countDown?.minutes <10 ? '0' + countDown?.minutes : countDown?.minutes}:
      {seconds <10 ? '0' + seconds : seconds}
    </div>
  );
};

export default CountDown;