import React from 'react'
import { IonCard, IonCardHeader, IonCardSubtitle } from '@ionic/react';
import './dateCard.css';

interface IDateCard {
  timestamp: number;
}

const DateCard: React.FC<IDateCard> = ( {timestamp} ) => {
  return (
    <IonCard className='dateCard'>
      <div className='hour'>
        {new Date(timestamp * 1000).toLocaleTimeString("en-GB", { hour: 'numeric', minute: '2-digit' })}&nbsp;hs.
      </div>
      <IonCardHeader className='dateCardHeader'>
        <IonCardSubtitle className='dateCardSubtitle'>
          {new Date(timestamp * 1000).toLocaleDateString("en-GB", { month: 'numeric', day: 'numeric' })}          
        </IonCardSubtitle>
      </IonCardHeader>
    </IonCard>    
  )
}

export default DateCard;
