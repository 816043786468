
import { useContext, useEffect, useState } from 'react';
import { IonButton, IonButtons, IonContent, IonHeader, IonIcon, IonPage, IonTitle, IonToolbar, useIonActionSheet, useIonLoading } from '@ionic/react';
import { alarmOutline, globe } from 'ionicons/icons';
import { LanguageContext } from '../../contexts';

import './mainLayout.css';

interface IMainLayout {
  title: string;
  children: React.ReactNode;
  style?: any;
}

const MainLayout: React.FC<IMainLayout> = ({title, children, style}) => {
  const [showNotificationToggle, setShowNotificationToggle] = useState<boolean>(false);
  const [present] = useIonActionSheet();
  const [loading] = useIonLoading();

  
  const languageContext = useContext(LanguageContext);
  
  const grantNotificationPermission = () => {
    Notification.requestPermission().then((result) => {
      if (result === 'granted') {
        setShowNotificationToggle(false);
        navigator.serviceWorker.ready.then((registration) => {
          registration.showNotification('Notificaciones activadas', {
          });
        });      
      }
    });    
  };

  const handleLangMenu = (data: any) => {
    if (data.data && data?.data?.action !== 'x') {
      languageContext.setLang(data.data.action);
      loading({
        message: languageContext.lang === 'spa' ? 'Mudando de idioma...' : 'Cambiando de idioma...',
      })      
      setTimeout(() => {
        window.location.reload();
      }, 2000);
    }
  }

  useEffect(() => {
    try {      
      if("PushManager" in window) {
        setShowNotificationToggle(true);      
      }
    } catch (error) {
      setShowNotificationToggle(false);      
    }
  }, [])

  useEffect(() => {
    try {      
      if("PushManager" in window) {
        Notification.permission === "default" ? setShowNotificationToggle(true) : setShowNotificationToggle(false);      
      }
    } catch (error) {
      
    }
  })
  
  return (
    <IonPage>
      <IonHeader>
        <IonToolbar>
          <IonTitle>{title}</IonTitle>
          <IonButtons collapse={false} slot="end">
            {
              showNotificationToggle && <IonButton onClick={()=>grantNotificationPermission()}><IonIcon icon={alarmOutline}></IonIcon></IonButton>
            }
            <IonButton
              onClick={() =>
                present({
                  header: 'Idioma',
                  buttons: [
                    {
                      text: 'Español',
                      data: {
                        action: 'spa',
                      },
                    },
                    {
                      text: 'Português',
                      data: {
                        action: 'por',
                      },
                    },
                    {
                      text: 'Cancelar',
                      role: 'cancel',
                      data: {
                        action: 'x',
                      },
                    },
                  ],
                  onDidDismiss: ({ detail }) => handleLangMenu(detail),
                })
              }
            >
              <IonIcon slot="start" icon={globe} />{languageContext.lang === 'spa' ? 'ES' : 'PT'}
            </IonButton>
          </IonButtons>
        </IonToolbar>
      </IonHeader>
      <IonContent fullscreen>
        <IonHeader collapse="condense">
          <IonToolbar>
            <IonTitle size="large">{title}</IonTitle>
          </IonToolbar>
        </IonHeader>
        <div style={style}>
          {children}
        </div>
      </IonContent>
    </IonPage>
  );
};

export default MainLayout;
