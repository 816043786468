import { IonAvatar} from '@ionic/react';
import './teamList.css';

interface ITeamList {
  name: string;
  picture: string;
  points?: number | undefined;
}

const TeamList: React.FC<ITeamList> = ({name, picture, points}) => {
  return (
    <div className='teamContainer'>
      <IonAvatar className='avatar'>
        <img src={picture} alt={name?.charAt(0)}/>
      </IonAvatar>
      <div className='teamName'>
        {name}
      </div>
      {
        points !== undefined && (
          <div className='points'>
            {points}
          </div>
        )
      }
    </div>
  );
};

export default TeamList;
