import { IonCard, IonCardContent, IonCardHeader, IonCardSubtitle, IonCardTitle, IonItem, IonLabel, IonList, IonThumbnail } from '@ionic/react';
import React from 'react';
import './groupCard.css';
interface ICountry {
  name: string;
  flag: string;
};

interface IGroupCard {
  group: string;
  countries: Array<ICountry>;
};

const GroupCard: React.FC<IGroupCard> = ({group, countries}) => {
  return (
    <IonCard>
      <IonCardHeader>
        <IonCardTitle>Grupo {group}</IonCardTitle>
        {/* <IonCardSubtitle>Card Subtitle</IonCardSubtitle> */}
      </IonCardHeader>
      <IonCardContent>
        <IonList>
          {
            countries.map((country: ICountry) => 
              <IonItem key={country.name} className="ionItem" routerLink={`/fixtures/${country.name}`}>
                <IonThumbnail slot="start">
                  <img alt={country.name} src={country.flag} />
                </IonThumbnail>
                <IonLabel>{country.name}</IonLabel>
              </IonItem>
            )
          }
        </IonList>
      </IonCardContent>
    </IonCard>    
  )
}

export default GroupCard