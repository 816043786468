import { IonSpinner } from '@ionic/react';
import React from 'react';
import './preloader.css';

const Preloader: React.FC = () => {
  return (
    <div className="spinnerContainer">
      <IonSpinner name="dots"></IonSpinner>
    </div>

  );
}

export default Preloader